export default {
  data() {
    return {
      m_scrollTicking: false
    };
  },
  methods: {
    /*
     * @function - m_setScrollPosition
     * Adds scroll Y position to vuex
     * @param - none
     */
    m_setScrollPosition() {
      const { scrollY } = window;

      if (!this.m_scrollTicking) {
        window.requestAnimationFrame(() => {
          this.$store.commit("Global/setWindow", { attribute: "scroll", value: scrollY });
          this.m_scrollTicking = false;
        });
      }

      this.m_scrollTicking = true;
    },
    /*
     * @function - m_setWindowSizes
     * Adds window sizes to vuex
     * @param - none
     */
    m_setWindowSizes() {
      const { innerHeight, innerWidth } = window;

      this.$store.commit("Global/setWindow", { attribute: "width", value: innerWidth });
      this.$store.commit("Global/setWindow", { attribute: "height", value: innerHeight });
    },
    /*
     * @function - m_setOnline
     * Adds online attribute to vuex
     * @param - none
     */
    m_setOnline() {
      this.$store.commit("Global/setOnline", navigator.onLine);
    }
  },
  mounted() {
    window.addEventListener("scroll", this.m_setScrollPosition);
    window.addEventListener("resize", this.m_setWindowSizes);
    window.addEventListener("online", this.m_setOnline);
    window.addEventListener("offline", this.m_setOnline);
    this.m_setScrollPosition();
    this.m_setWindowSizes();
    this.m_setOnline();
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.m_setScrollPosition);
    window.removeEventListener("resize", this.m_setWindowSizes);
    window.removeEventListener("online", this.m_setOnline);
    window.removeEventListener("offline", this.m_setOnline);
  }
};
