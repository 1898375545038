const { NODE_ENV } = process.env;

export default {
  data() {
    return {
      // refresh variables
      _refreshing: false,
      _registration: null
    };
  },
  created() {
    if (NODE_ENV !== "production") {
      return;
    }

    // Listen to our custom event from the SW register state
    window.addEventListener("beforeinstallprompt", this._serviceWorkerAskForInstall, {
      once: true
    });
    // Listen to our custom event from the SW new updates state
    document.addEventListener("serviceWorkerUpdated", this._serviceWorkerUpdateAvailable, {
      once: true
    });

    // Prevent multiple refreshes
    if (navigator && navigator.serviceWorker) {
      navigator.serviceWorker.addEventListener("controllerchange", () => {
        if (this._refreshing) return;
        this._refreshing = true;
        // Here the actual reload of the page occurs
        window.location.reload();
      });
    }
  },
  methods: {
    _serviceWorkerAskForInstall(event) {
      const messagerKey = "INSTALL_PWA";
      event.preventDefault();
      // Stash the event so it can be triggered with messager.
      setTimeout(
        () =>
          this.$bus.$emit("Messager: add text", {
            value: this.$t("serviceWorker.install"),
            style: "warning",
            timeout: "infinite",
            key: messagerKey,
            action: {
              icon: "download",
              fn: () => {
                this.$bus.$emit("Messager: remove text", messagerKey);
                event.prompt();
              }
            }
          }),
        3500
      );
    },
    // Store the SW registration so we can send it a message
    // To alert the user there is an update they need to refresh for
    _serviceWorkerUpdateAvailable(event) {
      this._registration = event.detail;
      this.$bus.$emit("Messager: add text", {
        value: this.$t("serviceWorker.updated"),
        style: "warning",
        avoidClose: true,
        timeout: "infinite",
        action: { icon: "redo-alt", fn: () => this._refreshApp() }
      });
    },

    // Called when the user accepts the update
    _refreshApp() {
      // Make sure we only send a 'skip waiting' message if the SW is waiting
      if (!this._registration || !this._registration.waiting) return;
      // send message to SW to skip the waiting and activate the new SW
      this._registration.waiting.postMessage({ type: "SKIP_WAITING" });
    }
  }
};
