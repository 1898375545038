<template>
  <div id="app">
    <content-unavailable-mask v-if="appWithNoContent" />
    <template v-else>
      <loading-mask @loaded="loaded" :percentage="$store.getters['Global/assetsPercentage']" />
      <template v-if="appIsLoaded">
        <messager />
        <top-nav />
        <slot name="languageSelection"></slot>
        <div class="app-content">
          <slot name="content"></slot>
        </div>
        <bottom-nav />
      </template>
    </template>
  </div>
</template>

<script>
import WindowMixin from "@/mixins/App/Window";
import ServiceWorkerMixin from "@/mixins/App/ServiceWorker";

const ContentUnavailableMask = () =>
  import(
    /* webpackChunkName: "ContentUnavailableMask" */ "@/components/Global/ContentUnavailableMask.vue"
  );
const LoadingMask = () =>
  import(/* webpackChunkName: "LoadingMaskComponent" */ "@/components/Global/LoadingMask.vue");
const Messager = () =>
  import(/* webpackChunkName: "ContactPage" */ "@/components/Global/Messager.vue");
const TopNav = () => import(/* webpackChunkName: "TopNavComponent" */ "@/components/Global/TopNav");
const BottomNav = () =>
  import(/* webpackChunkName: "BottomNavComponent" */ "@/components/Global/BottomNav.vue");

export default {
  name: "App",
  components: {
    ContentUnavailableMask,
    LoadingMask,
    Messager,
    TopNav,
    BottomNav
  },
  mixins: [WindowMixin, ServiceWorkerMixin],
  watch: {
    ["$store.state.Global.online"](onlineState) {
      if (!onlineState) {
        this.$bus.$emit("Messager: add text", {
          value: this.$t("connection.offline"),
          style: "danger"
        });

        return;
      }

      if (this.appWithNoContent) {
        location.reload();
        return;
      }

      this.$bus.$emit("Messager: add text", {
        value: this.$t("connection.online"),
        style: "success"
      });
    }
  },
  data() {
    return {
      appIsLoaded: false,
      appWithNoContent: false
    };
  },
  methods: {
    /*
     * @function - loaded
     * 1) Set appIsLoaded to true
     * 2) Scrolls window to top
     * 3) Emits LoadingMask: loaded that is being listened across the app
     * @param - none
     */
    loaded() {
      this.appIsLoaded = true;
      window.scroll(0, 0);

      this.$nextTick(() => this.$bus.$emit("LoadingMask: loaded"));
    },
    /*
     * @function - setAppLanguage
     * 1) Loads available translations
     * 2) If 'idioma' query param exists in available translations loads it
     * 3) If 'idioma' query param does not exist replace query param with this.$utils.defaultLanguage
     * 5) Loads translations with encountered language
     * 6) Sets locale data for $i18n and html tag lang attribute
     * 7) Commits translations loaded asset
     * @param - none
     */
    async setAppLanguage() {
      const applicationLanguage = document.getElementById("applicationLanguage");

      this.$store.commit(
        "Global/setCurrentLanguage",
        applicationLanguage && applicationLanguage.dataset && applicationLanguage.dataset.language
      );
      this.$store.commit(
        "Global/setCurrentPage",
        applicationLanguage && applicationLanguage.dataset && applicationLanguage.dataset.page
      );

      try {
        let translationsContentData;
        if (this.$store.state.Global.online) {
          translationsContentData = (await this.$dependencies.neysla.translationsContent.get())
            .data;
          await this.$dependencies.idb.set("translationsContentData", translationsContentData);
        } else {
          translationsContentData = await this.$dependencies.idb.get("translationsContentData");
        }

        this.$store.commit("Global/setLanguages", translationsContentData);
        const language = this.$store.state.Global.currentLanguage;

        if (!translationsContentData.includes(language)) {
          this.$store.commit("Global/setCurrentLanguage", this.$utils.defaultLanguage);
        }

        let translationsContentPageData;
        if (this.$store.state.Global.online) {
          translationsContentPageData = (
            await this.$dependencies.neysla.translationsContentPage.get({
              delimiters: [language, this.$store.state.Global.currentPage]
            })
          ).data;
          await this.$dependencies.idb.set(
            `${language}-${this.$store.state.Global.currentPage}`,
            translationsContentPageData
          );
        } else {
          translationsContentPageData = await this.$dependencies.idb.get(
            `${language}-${this.$store.state.Global.currentPage}`
          );
        }

        if (translationsContentPageData) {
          this.$i18n.setLocaleMessage(language, translationsContentPageData);
          this.$i18n.locale = language;
        } else {
          this.appWithNoContent = true;
        }

        setTimeout(
          () => this.$store.commit("Global/loadAsset", "translations"),
          this.$store.state.Global.online ? 0 : 1500
        );
      } catch (error) {
        console.error("App - m_setAppLanguage: error loading language data", error);
      }
    }
  },
  async mounted() {
    this.$utils.printCurrentVersion("ui");
    this.$utils.changeApplicationVisibility();

    await this.$dependencies.neysla.initEndpoints();

    await this.$nextTick();
    this.$utils.printCurrentVersion("api");

    await this.setAppLanguage();
  }
};
</script>

<style lang="scss">
.app {
  &-content {
    margin-bottom: 27rem;
    margin-top: 4.25rem;

    @include breakpoint($small) {
      margin-top: 5rem;
    }

    @include breakpoint($medium) {
      margin-bottom: 22rem;
    }

    @include breakpoint($large) {
      margin-top: 5.75rem;
    }
  }
}
</style>
